import React, { useState, useEffect, useRef  } from 'react';
import Header from '../Header';
import Footer from '../Footer';
import { API_URL, BASE_URL } from '../Config';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import axios from "axios";
import Select from 'react-select';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import Swal from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import config from "../common/Config";




const PickingCreate = (props) => {
  const [options, setOptions] = useState([]);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [picker, setSelectedPicker] = useState('');
  const [newOrders, setNewOrders] = useState([]);
  const [selectedOrderIds, setSelectedOrderIds] = useState([]);
  const [draw, setDraw] = useState(1);
  const [start, setStart] = useState(0);
  const [length, setLength] = useState(50);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [customerInputValue, setCustomerInputValue] = useState('');

  const navigateToOrderHistory = useNavigate();

  const pickerRef = useRef(null);
 //useEffect(() => {
  async function fetchData() 
  {

     const accessToken = localStorage.getItem('accessToken'); 

    const formData = new FormData();
    formData.append('searchByFromdate', fromDate);
    formData.append('searchByTodate', toDate);
    formData.append('draw', draw);
    formData.append('start', start);
    formData.append('length', length);
    formData.append('pickerId', picker);
     formData.append('authorization', `Bearer ${accessToken}`);

    const apiUrl = `${API_URL}/create/picking`;

   
    if (accessToken === null) 
    {
      window.location.href = `${config.BASE_URL}/login`; 
      return;
    }

    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          authorization: `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to create picking');
      }

      const data = await response.json();

  // Set new orders from the response data
  setNewOrders(Array.isArray(data.data) ? data.data : []); 


} catch (error) {
  console.error('Error creating picking:', error);
}

}


  //   fetchData();
  // }, []); 

  useEffect(() => {
    fetchData();
  },[]);

  useEffect(() => {
    async function fetchPickerOptions() {
      try {

        const accessToken = localStorage.getItem('accessToken'); 
        
        if (accessToken === null) 
        {
          window.location.href = `${config.BASE_URL}/login`; 
          return;
        }

        const response = await axios.get(`${API_URL}/getPicker`, {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        });
        
        if (!response.data || !response.data.picker || !Array.isArray(response.data.picker)) {
          throw new Error('Invalid response format or empty picker array');
        }
        
        // const formattedOptions = response.data.picker.map(item => ({
        //   key: `${item.first_name} ${item.last_name}`,
        //   value: item.id,
        // }));

        //const data = await response.json();

       // console.log(response.data.picker);

       if (Array.isArray(response.data.picker)) {
        const formattedOptions = response.data.picker.map(item => ({
          label: `${item.first_name} ${item.last_name}`,
          value: item.id,
        }));
        setCustomerOptions(formattedOptions);
      }
      
       // setOptions(formattedOptions);
     } catch (error) {
      console.error('Error fetching picker options', error);
        // Handle error as needed, e.g., show a message to the user
      }
    }
    
    fetchPickerOptions();
  }, []);


  const handleChange = (selectedOption) => {
    setCustomerInputValue(selectedOption);
  };

  /*const handlePickerChange = (e) => {
  setSelectedPicker(e.target.value);
};
*/
const handlePickerChange = (inputValue) => {
  setSelectedPicker(inputValue);
};





useEffect(() => {
  const table = $('#neworderTable').DataTable({
    paging: true,
    lengthMenu: [10, 20, 50],
    searching: true,
    ordering: false,
    info: true,
    responsive: true,
    autoWidth: false,
    language: {
      paginate: {
        first: 'First',
        last: 'Last',
        next: 'Next',
        previous: 'Previous',
      },
      search: 'Search:',
    },
    data: newOrders,
    columns: [
    { 
      data: null,
      width: '5%', className: 'text-center', 
      render: function (data, type, row) {
        return `<div class="radio-tbl"><label class="checkbox"><input type="checkbox" class="checkbox multi-select input-xs" name="val[]" value="${row.id}"/><span class="default"></span></label></div>`;
       
      }
    },
    { data: 'date', width: '10%', className: 'text-center'  },
    {
      data: 'reference_no',
      width: '10%', 
      className: 'text-center',
      
      render: function (data, type, row) {
        return '<a href="'+BASE_URL+'/picking/picking_list/view/' + row.id + '">' + row.reference_no + '</a>';
      }
    },
    {
      data: 'customer',
      render: function (data) {
        return `<p class="cut-text">${data}</p>`;
      },
    },
    { data: 'route_number' ,width: '10%', className: 'text-center'},
    { data: 'first_name' ,width: '10%', className: 'text-center'},
    { data: 'deliverydate' ,width: '10%', className: 'text-center'},   
    ],
  });


  return () => {
    table.destroy();
  };
  }, [newOrders, selectedOrderIds]); // Ensure to include selectedOrderIds in dependencies

  // Handle button click to submit selected orders and picker to another API
  const handleSelectionSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission

    const formData = new FormData(event.target);
    console.log(customerInputValue.value);


    formData.append('picker', customerInputValue.value);
    if (!customerInputValue.value || customerInputValue.value === "undefined") {
      Swal.fire({
        title: 'Failed!',
        text: 'Please select Picker first.',
        icon: 'error',
        showConfirmButton: false,
        timer: 2000,
      });
      pickerRef.current.focus(); 
      return;
    }

    const accessToken = localStorage.getItem('accessToken');
    
    if (accessToken === null) {
      window.location.href = `${config.BASE_URL}/login`;
      return;
    }

    try {
      const response = await fetch(`${API_URL}/create_picking`, {
        method: 'POST',
        headers: {
          'authorization': `Bearer ${accessToken}`,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to submit selection');
      }

      const data = await response.json();

      if (data.status === 'true') {
        const picklistUrl = data.picklist_number;
          const picklistNo = picklistUrl.substring(picklistUrl.lastIndexOf('/') + 1);
        Swal.fire({
          title: "Success!",
          text: "Create Picking successfully.",
          icon: "success",
          timer: 1500,
          showConfirmButton: false,
        }).then(() => 
        {
          
          
          window.location.href = config.BASE_URL + `/sales/view_picking_list/${picklistNo}`;
          
      
        });
        
        

       

        /*setTimeout(() => {
           navigateToOrderHistory(`/sales/view_picking_list/${picklistNo}`);
        }, 500);*/

      } else {
        Swal.fire({
          title: 'Failed!',
          text: 'Please select order first.',
          icon: 'error',
          showConfirmButton: false,
          timer: 2000,
        });
        
      }

      
     // fetchData();

    } catch (error) {
      console.error('Error submitting selection:', error);
    }
  };


return (
  <div>
  <Header />
  <nav className="breadcrumbs">
  <div className="container-fluid">
  <div className="row">
  <div className="col-12 col-lg-12 col-sm-12">
  <a href="#"><i className="fa fa-home"></i> </a> 
  <span className="last-crumb">Create Picking</span>
  </div>
  </div>
  </div>
  </nav>

  
  <div className="main-container">
  <div className="container-fluid">
  <div className="row">
  <div className="col-12 col-lg-12 col-sm-12">
  <div>
  <form id="picking_create" onSubmit={handleSelectionSubmit}>
  <div className="dashform">
  <div style={{ float: 'left' }}>
  <div style={{ width: '300px', float: 'left', marginRight: '15px' }}>


  <Select
  ref={pickerRef}
  isClearable
  onInputChange={handlePickerChange}
  onChange={handleChange}
  options={customerOptions}
  placeholder="Select Picker"
  value={customerInputValue}
  />
  </div>
  <button type="submit" className="multisubmit">
  Submit
  </button>
  </div>
  </div>

  
  <div className='total-manifests-area dashboard'>
  <div className="verical-scrl-wrapper common-table autoheight-table" id="style-3">

  <table className="table table-bordered dataTable resizable" id="neworderTable">
  <thead>
  <tr>
  <th>#</th>
  <th>Order Date</th>
  <th>Order Id</th>
  <th>Customers (Ac/No)</th>     
  <th>Route No</th>
  <th>Order By</th>
  <th>Delivery Date</th>                       
  </tr>
  </thead>
  <tbody>
  {newOrders.map((item, index) => (
    <tr key={index}>

    </tr>
    ))}
    </tbody>
    </table>

    </div>


    </div>


    </form>
    </div>
    </div>
    </div>
    </div>
    </div>
    <Footer />
    </div>
    );
  };

  export default PickingCreate;

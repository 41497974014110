import React, { useState, useEffect, useRef, useCallback  } from 'react';
import Select from 'react-select';
import axios from "axios";
import Header from '../Header';
import Footer from '../Footer';
import FlashMessage from '../FlashMessage';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import $ from 'jquery';
import 'datatables.net-bs5';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { API_URL, BASE_URL } from '../Config';
import config from "../common/Config";
import jsPDF from 'jspdf';  // Library to generate PDF
import 'jspdf-autotable';  // Plugin for auto table


const PurchasesOrder = () => {

	const [draw, setDraw] = useState(1);
  	const [start, setStart] = useState(0);
  	const [length, setLength] = useState(50);
  	const [purchaseOrder, setPurchaseOrder] = useState([]);

	const accessToken = localStorage.getItem('accessToken'); 

	const purchaseOrderRef = useRef(null);
	const [searchValue, setSearchValue] = useState([]);


	/*const fetchPurchaseOrderList = async () => {
		const params = new URLSearchParams();
	    params.append('draw', draw);
	    params.append('start', start);
	    params.append('length', length);

	    const apiPurchaseOrderUrl = `${API_URL}/purchases/grnlist?${params.toString()}`;
	    try {
	        const response = await fetch(apiPurchaseOrderUrl, {
	            method: 'GET',
	            headers: {
	                authorization: `Bearer ${accessToken}`,
	            },
	        });

	        if (!response.ok) {
	            throw new Error('Failed to fetch purchase order');
	        }
	        const data = await response.json();
	        setPurchaseOrder(data.data);
	        //console.log(data.data);

	    } catch (error) {
	        console.error('api Error fetching purchase order:', error);
	    }
	};*/

	/*useEffect(() => {

  		if (accessToken === null){
            window.location.href = `${config.BASE_URL}/login`; 
            return;
        }
        
        fetchPurchaseOrderList();
  	},[]);*/

  /*	useEffect(() => {
		  // Initialize DataTable
		  const table = $('#purchaseOrderTable').DataTable({
		    pageLength: 50,
		    paging: true,
		    lengthMenu: [10, 20, 50],
		    searching: true,
		    ordering: false,
		    info: true,
		    responsive: true,
		    autoWidth: false,
		    language: {
		      paginate: {
		        first: 'First',
		        last: 'Last',
		        next: 'Next',
		        previous: 'Previous',
		      },
		      search: 'Search:',
		    },
		    data: purchaseOrder,
		    columns: [
		      
		      	{ data: 'date', className : 'text-center', width: '9%' },
		      	{ data: 'reference_no', className : 'text-start', width: '8%' },
		      	{ data: 'supplier', className : 'text-start' },
		      	{ data: 'total_tax', className : 'text-end', width: '7%' },
		      	{ data: 'grand_total', className : 'text-end', width: '8%' },
		      	{ data: 'status', className : 'text-center', width: '7%' },
		      
		      	{
	          		data: null,
	          		width : '5%',
	          		render: function (data, type, row) {
	          			const fetchProductPrintCall = `fetchProductPrint(${row.id})`;
	          			if(row.status === 'Draft'){
	          				return `<div>
		                      	<a class="icon" href="#" data-bs-toggle="dropdown" aria-expanded="false">
		                       	<i class="bi bi-three-dots-vertical"></i>
		                      	</a>
	                      		<ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
			                        
			                        <li>
			                        	<a href="${BASE_URL}/purchases/view/${row.id}" class="deductionbtn">View</a>
			                        </li>
			                        <li>
			                        	<a href="${BASE_URL}/purchases/edit_po/${row.id}" class="deductionbtn">Edit PO</a>
			                        </li>
			                        <li>
			                        	<a href="${BASE_URL}/purchases/add_grn/${row.id}" class="deductionbtn">Add GRN</a>
			                        </li>
		                      	</ul>
	                    	</div>`;
	          			}else{
	          				return `<div>
		                      	<a class="icon" href="#" data-bs-toggle="dropdown" aria-expanded="false">
		                       	<i class="bi bi-three-dots-vertical"></i>
		                      	</a>
	                      		<ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
			                      	<li>
	                                    <a class="dropdown-item print-btn" href="#" data-id="${row.id}">Print</a>
	                                </li>
			                        <li>
			                        	<a href="${BASE_URL}/purchases/view/${row.id}" class="deductionbtn">View</a>
			                        </li>
			                        
		                      	</ul>
	                    	</div>`;
	          			}
	            		
	        		}
	        	}
		     
		    ],
		    drawCallback: function (settings) {

		    	$('#purchaseOrderTable').off('click', '.print-btn').on('click', '.print-btn', function (event) {

		     	
	                event.preventDefault();
	                const pdtId = $(this).data('id');
	                fetchProductPrint(pdtId);
	            });
		    }
		  });

		  

		  return () => {
		    table.destroy();
		  };
		}, [purchaseOrder]);*/

  	const fetchProductPrint = async (pdtId) => {
  		console.log(pdtId);
        const formData = new FormData();
        formData.append('id', pdtId);
        const apiUrl = `${API_URL}/purchases/purchases_print`;
        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    authorization: `Bearer ${accessToken}`,
                },
                body: formData,
            });

            if (!response.ok) {
                throw new Error('Failed to fetch Not Order Report');
            }
            const data = await response.json();
            generatePDF(data);
            // Process the data as needed
        } catch (error) {
            console.error('api fail Error fetching Not Order Report:', error);
        }
    };

    const generatePDF = (data) => {
	    const doc = new jsPDF();

	    // Header
	    const header = [
	        [`Order Summary for ${data.shop_details.shop_name}`, `Address: ${data.shop_details.address}`],
	        [`COMPANY REG NO : ${data.shop_details.company_reg_no}`, `Email: ${data.shop_details.email}`],
	    ];
	    const header2 = [
	        [`${data.purchases_details.name}`, `${data.purchases_details.reference_no}`],
	        [`Purchase Order No: ${data.purchases_details.reference_no}`, `Purchase Date: ${data.purchases_details.date}`],
	        [`Cust Account No: ${data.purchases_details.po_id}`, `Due Date: ${data.purchases_details.date}`],
	    ];

	    // Define columns for autoTable
	    const columns = [
	        { title: 'Description', dataKey: 'description' },
	        { title: 'Quantity', dataKey: 'quantity' },
	       
	    ];

	    // Prepare data for the table
	    const tableData = data.purchases_items.map(item => ({
	        description: `${item.product_code} - ${item.product_name}`,
	        quantity: parseInt(item.quantity, 10),
	       
	    }));

	    // Add header to the document
	    doc.autoTable({
	        head: header,
	        startY: 10,
	        headStyles: { fontSize: 10, textColor: '#666', fillColor: '#eee' },
	        margin: { top: 10 },
	        showHead: 'firstPage',
	    });

	    // Add second header below the first one
	    doc.autoTable({
	        head: header2,
	        startY: doc.previousAutoTable.finalY + 5,
	        headStyles: { fontSize: 10, textColor: '#666', fillColor: '#f9f9f9' },
	        margin: { top: 10 },
	        showHead: 'firstPage'
	    });

	    // Add table body
	    const itemsPerPage = 20;
	    const totalPages = Math.ceil(tableData.length / itemsPerPage);

	    for (let i = 0; i < totalPages; i++) {
	        const startIndex = i * itemsPerPage;
	        const endIndex = startIndex + itemsPerPage;
	        const pageData = tableData.slice(startIndex, endIndex);

	        if (i > 0) {
	            doc.addPage();
	        }

	        doc.autoTable({
	            columns: columns,
	            body: pageData,
	            startY: doc.previousAutoTable.finalY + 5 || 20,
	            styles: { fontSize: 10 },
	        });
	    }

	    // Save the PDF
	    const uniqueNumber = Date.now();
	    doc.save(`invoice_${uniqueNumber}.pdf`);
	};

	const formatNumber = (num) => {
	    return parseFloat(num).toFixed(2);
	};



	const getPurchaseOrderList = () => {
	    const $table = $(purchaseOrderRef.current);
	    const searchValue = $('#searchValue').val();

	    // Destroy previous DataTable instance if it exists
	    if ($.fn.DataTable.isDataTable($table)) {
	        $table.DataTable().destroy();
	    }

	    // Initialize DataTable
	    $table.DataTable({
	        processing: true,
	        serverSide: true,
	        ajax: {
	            url: `${API_URL}/purchases/grnlist`,
	            type: 'GET',
	            headers: {
	                authorization: `Bearer ${accessToken}`,
	            },
	            data: function (d) {
	                return $.extend({}, d, {
	                    draw: d.draw,
	                    start: d.start,
	                    length: d.length,
	                    searchValue: searchValue,  // Pass search value for server-side filtering
	                });
	            },
	            dataSrc: function (json) {
	                // Set the filtered record count
	                

	                // Return the actual data
	                return Array.isArray(json.data) ? json.data : [];
	            }
	        },
	        language: {
	            paginate: {
	                first: 'First',
	                last: 'Last',
	                next: 'Next',
	                previous: 'Previous',
	            },
	            search: 'Search:',
	        },
	        columns: [
	            { data: 'date', className: 'text-center', width: '9%' },
	            { data: 'reference_no', className: 'text-start', width: '8%' },
	            { 
	            	data: null, 
	            	className: 'text-start',
	            	render : function(data, type, row){
	            		return `<span>${row.supplier}</span>`;
	            	}

	            },
	            { data: 'total_tax', className: 'text-end', width: '7%' },
	            { data: 'grand_total', className: 'text-end', width: '8%' },
	            { data: 'status', className: 'text-center', width: '7%' },
	            {
	                data: null,
	                width: '5%',
	                render: function (data, type, row) {
	                    const fetchProductPrintCall = `fetchProductPrint(${row.id})`;
	                    if (row.status === 'Draft') {
	                        return `
	                            <div>
	                                <a class="icon" href="#" data-bs-toggle="dropdown" aria-expanded="false">
	                                    <i class="bi bi-three-dots-vertical"></i>
	                                </a>
	                                <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
	                                    <li>
	                                        <a href="${BASE_URL}/purchases/view/${row.id}" class="deductionbtn">View</a>
	                                    </li>
	                                    <li>
	                                        <a href="${BASE_URL}/purchases/edit_po/${row.id}" class="deductionbtn">Edit PO</a>
	                                    </li>
	                                    <li>
	                                        <a href="${BASE_URL}/purchases/add_grn/${row.id}" class="deductionbtn">Add GRN</a>
	                                    </li>
	                                </ul>
	                            </div>
	                        `;
	                    } else {
	                        return `
	                            <div>
	                                <a class="icon" href="#" data-bs-toggle="dropdown" aria-expanded="false">
	                                    <i class="bi bi-three-dots-vertical"></i>
	                                </a>
	                                <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
	                                    <li>
	                                        <a class="dropdown-item print-btn" href="#" data-id="${row.id}">Print</a>
	                                    </li>
	                                    <li>
	                                        <a href="${BASE_URL}/purchases/view/${row.id}" class="deductionbtn">View</a>
	                                    </li>
	                                </ul>
	                            </div>
	                        `;
	                    }
	                }
	            }
	        ],
	        columnDefs: [
	            {
	                orderable: false,
	                targets: '_all'
	            },
	            {
	                targets: 2, 
	                render: function (data) {
	                    return data === '1' ? 'Active' : 'Inactive'; 
	                }
	            }
	        ],
	        pageLength: 10,
	        drawCallback: function (settings) {
	            // Attach print functionality
	            $('#purchaseOrderTable').off('click', '.print-btn').on('click', '.print-btn', function (event) {
	                event.preventDefault();
	                const pdtId = $(this).data('id');
	                fetchProductPrint(pdtId);
	            });
	        }
	    });
	};


	useEffect(() => {
	    if (accessToken === null) {
	        window.location.href = `${config.BASE_URL}/login`;
	        return;
	    }

	    // Initialize DataTable
	    getPurchaseOrderList();

	    // Cleanup function to destroy DataTable on unmount or when search value changes
	    return () => {
	        if (purchaseOrderRef.current && $.fn.DataTable.isDataTable(purchaseOrderRef.current)) {
	            $(purchaseOrderRef.current).DataTable().destroy();
	        }
	    };
	}, [searchValue]);
		return(
		<>
			<Header />
			<div className="breadcrumbs">
	          	<div className="container-fluid">
		            <div className="row">
		                <div className="col-12 col-lg-12 col-sm-12"> 
		                  <Link to="/Dashboard"><i className="fa fa-home"></i></Link>   
		                  <Link to="/purchases/purchases_order">Purchases</Link> 
		                  <Link to="/purchases/purchases_order">Purchases Order</Link> 
		                </div>
		            </div>
	          	</div>
	      	</div>
	      	<div className="main-container">
			    <div className="container-fluid">

			    	<div style={{position : 'relative'}} >
	                   
	                    <div>
	                    	<Link to="/purchases/add">
	                    		<button type="button" class="acceptbtn" >Add Purchase Order</button>
	                    	</Link>
	                    </div>
		<div className='total-manifests-area dashboard'>
	                    <div className="verical-scrl-wrapper common-table autoheight-table" id="style-3">
	                        <table className="table table-bordered dataTable resizable" id="purchaseOrderTable" ref={purchaseOrderRef}>
	                            <thead>
	                                <tr>
	                                    <th>Date</th>
	                                    <th>Po No</th>
	                                    <th>Supplier</th>
	                                    <th>VAT</th>
	                                    <th>Grand Total</th>
	                                    <th>Status</th>
	                                    <th>Action</th>
	                                </tr>
	                            </thead>
	                            <tbody>
	                                
	                            </tbody>
	                            <tfoot>

					            </tfoot>
	                        </table>
	                    </div>
						</div>

	                </div>

			    </div>
			</div>

	      	<Footer />

		</>

	);

}
export default PurchasesOrder;